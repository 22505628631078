.navbar {
    width: 100%;
    height: 3.5rem;
    background-color: rgb(32, 32, 32);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1;
    display: flex;
    justify-content: space-between;
}

.navbar:hover .title{
    animation: title 2s linear infinite;
}

.navbar:hover .logoutButton .logoutSvg {
    fill: #de6161;
}

.lin_grad_back {
    color: transparent;
    -webkit-background-clip: text;
    background-image: linear-gradient(90deg,#de6161,#2657eb,#de6161);
    background-repeat: repeat-x;
    background-size: 20rem;
}
.title {
    display: block;
    font-size: 1.7em;
    font-weight: bold;
    padding-top: 0.5rem;
    transition: transform 1s;
    width: 15rem;
    text-align: center;
}

.titleDev {
    width: 20rem !important;
}

.logoutSvg {
    fill: #2657eb;
    width: 1.5rem;
    height: 1.5rem;
    margin: 0.5rem;
    cursor: pointer;
    transition: fill 1s;
}

.logoutButton {
    background-color: transparent;
    border: none;
}

.flexSide {
    width: 3rem;
    display: flex;
    flex-shrink: 0;
}

.logoutButtonWrapper {
    display: flex;
    align-items: center;
    padding-right: 3rem;
}

@media (max-width: 600px) {
    .logoutButtonWrapper {
        padding: 0;
    }
}

.loadingBar {
    background-image: linear-gradient(90deg,#de6161,#2657eb,#de6161);
    background-repeat: repeat-x;
    position: absolute;
    top: 3.5rem;
    height: .6rem;
    width: 100%;
    transition: all 2s;
    z-index: 2;
    animation: loadingBar 2s linear infinite;
    display: none;
}

.active {
    display: block;
}

@keyframes loadingBar {
    0% {
        background-position-x: 0;
    }
    100% {
        background-position-x: 230rem;
    }
}

@keyframes title{
    0% {
        background-position-x: 0rem;
    }
    100% {
        background-position-x: 20rem;
    }
}
