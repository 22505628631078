.homeContainer {
    margin-top: 3.5rem;
    gap: 1rem;
    display: flex;
    align-items: center;
    width: 100vw;
    padding-top: 5rem;
    flex-direction: column;
}

.input {
    color: white;
    height: 1rem;
    border-radius: 100000px;
    transition: all 0.3s;
    outline: none;
    padding: 1rem;
    background-color: rgb(127, 127, 127);
    border: none;
    width: 20rem;
}

.button {
    background-image: linear-gradient(90deg,#de6161,#2657eb);
    filter: grayscale(0.4);
    text-shadow: 0 0 10px rgb(0, 0, 0);
    color: white;
    border: none;
    transition: all 0.3s;
    height: 2.5rem;
    width: 10rem;
    border-radius: 1000000px;
    font-size: 1.05em;
}

.button:hover {
    filter: grayscale(0);
    cursor: pointer;
}

.output {
    font-size: 2em;
}
